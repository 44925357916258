import React from 'react';
import Stack from '@mui/material/Stack';
import SalesReport from './SalesReport';
import SalesDetails from './SalesDetails';

export default function Reports() {
  return (
    <>
      <Stack spacing={4}>
        <SalesReport />
        <SalesDetails />
      </Stack>
    </>
  );
}
