import React, { useState, useMemo } from 'react';
import { CSVDownload } from 'react-csv';
import Auth from '@aws-amplify/auth';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const { REACT_APP_BACKEND_URL } = process.env;
const THIRTY_TWO_DAYS_IN_MILLISECONDS = 2764800000;

export default function SalesDetails() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reportData, setReportData] = useState();
  const [loading, setLoading] = useState();

  const disableButton = useMemo(() => {
    if (!startDate || !endDate) {
      return true;
    }

    const startDateJS = new Date(startDate);
    const endDateJS = new Date(endDate);
    if (endDateJS - startDateJS > THIRTY_TWO_DAYS_IN_MILLISECONDS) {
      return true;
    }

    return false;
  }, [startDate, endDate]);

  const handleReport = async () => {
    setReportData();
    setLoading(true);
    const startDateJS = new Date(startDate);
    startDateJS.setHours(0);
    startDateJS.setMinutes(0);
    startDateJS.setSeconds(0);

    const endDateJS = new Date(endDate);
    endDateJS.setHours(23);
    endDateJS.setMinutes(59);
    endDateJS.setSeconds(59);

    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getIdToken().getJwtToken();

    fetch(
      `${REACT_APP_BACKEND_URL}/reports/sales-details?startDate=${startDateJS.toISOString()}&endDate=${endDateJS.toISOString()}`,
      {
        headers: {
          authorization: accessToken,
        },
      },
    )
      .then((response) => response.json())
      .then(({ data }) => {
        setReportData(data);
        setLoading(false);
      });
  };

  return (
    <>
      {reportData && <CSVDownload data={reportData} target="_blank" />}
      <Typography variant="h4">Detalle de items por venta</Typography>
      <Typography>
        Reporte de items por ventas, por local, para un cierto rango de fecha
        (máximo un mes)
      </Typography>
      <Stack direction="row" spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Fecha de Inicio"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Fecha de Termino"
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            helperText="Máximo un mes"
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleReport}
          disabled={disableButton}
        >
          Generar
        </LoadingButton>
      </Stack>
    </>
  );
}
