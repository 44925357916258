import React, { useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '@aws-amplify/auth';
import orange from '@mui/material/colors/orange';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import LogoutIcon from '@mui/icons-material/Logout';
import StoreCentralLogo from '../../images/store-central.png';
import {
  logout,
  isLoggedInSelector,
  usernameSelector,
} from '../../lib/store/slices/auth';
import Home from '../Home';
import Reports from '../Reports';
import Authentication from '../Authentication';

const STORECENTRAL_THEME = createTheme({
  palette: {
    secondary: orange,
  },
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      align="center"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://storecentral.cl/" target="_blank">
        StoreCentral
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: 'black',
  display: 'flex',
  zIndex: theme.zIndex.drawer + 1,
}));

export default function Dashboard() {
  const dispatch = useDispatch();
  const currentUsername = useSelector(usernameSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const logoutCallback = useCallback(() => {
    Auth.signOut().then(() => dispatch(logout()));
  });

  if (!isLoggedIn) return <Authentication />;

  return (
    <ThemeProvider theme={STORECENTRAL_THEME}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <img height="40px" src={StoreCentralLogo} alt="logo" />
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <Typography>Hola {currentUsername}!</Typography>
              <IconButton color="inherit" onClick={logoutCallback}>
                <LogoutIcon />
              </IconButton>
            </Toolbar>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/reports" element={<Reports />} />
                  </Routes>
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
