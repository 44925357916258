import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Auth from '@aws-amplify/auth';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import DeleteIcon from '@mui/icons-material/Delete';
import debounce from 'lodash/debounce';

const { REACT_APP_BACKEND_URL } = process.env;

const INITIAL_STATE = [];
const WAIT_FOR_SEARCH = 300;
const MIN_LENGTH_TO_SEARCH = 3;
const SHOW_SNACKBAR_MS = 5000;

export default function SaleForm({ storeId }) {
  const [items, setItems] = useState(INITIAL_STATE);
  const [options, setOptions] = useState([]);
  const [snackMessage, setSnackMessage] = useState();
  const [sendingForm, setSendingForm] = useState(false);

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        productId: '',
        quantity: 1,
        price: 0,
      },
    ]);
  };

  const handleDeleteItem = (index) => () => {
    setItems(items.filter((_, itemIndex) => itemIndex !== index));
  };

  const handleOnChange = (index) => (event) => {
    const newItems = items.map((currentItem, itemIndex) => {
      if (index !== itemIndex) return currentItem;
      return { ...currentItem, [event.target.name]: event.target.value };
    });

    setItems(newItems);
  };

  const handleAutocompleteOnChange = (index) => (value) => {
    const newItems = items.map((currentItem, itemIndex) => {
      if (index !== itemIndex) return currentItem;
      return {
        ...currentItem,
        productId: (value && value.id) || '',
        price: (value && value.price) || '',
      };
    });

    setItems(newItems);
  };

  const handleEndSale = async () => {
    try {
      setSendingForm(true);

      const currentSession = await Auth.currentSession();
      const accessToken = currentSession.getIdToken().getJwtToken();

      await fetch(`${REACT_APP_BACKEND_URL}/sales`, {
        method: 'POST',
        headers: {
          authorization: accessToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          storeId,
          items: items.map(({ productId, quantity }) => ({
            productId,
            quantity,
          })),
        }),
      });

      setItems(INITIAL_STATE);
      setSendingForm(false);
      setSnackMessage('Venta registrada con éxito!');
    } catch {
      setSnackMessage(
        'Ha ocurrido un error al registrar tu venta, intenta nuevamente',
      );
      setSendingForm(false);
    }
  };

  const searchProduct = debounce(async (currentStoreId, query = '') => {
    if (query.length < MIN_LENGTH_TO_SEARCH || query.includes(' ')) return;

    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getIdToken().getJwtToken();

    const response = await fetch(
      `${REACT_APP_BACKEND_URL}/products?storeId=${currentStoreId}&q=${query}`,
      {
        headers: {
          authorization: accessToken,
        },
      },
    );

    const apiResponse = await response.json();
    const { data } = apiResponse;
    setOptions(data);
  }, WAIT_FOR_SEARCH);

  const totalPrice = useMemo(
    () => items.reduce((acc, { price, quantity }) => acc + price * quantity, 0),
    [items],
  );

  const isFormUncompleted = useMemo(
    () =>
      items.some(
        (item) => !item.productId || !item.quantity || item.quantity <= 0,
      ),
    [items],
  );

  return (
    <>
      <Snackbar
        open={snackMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={SHOW_SNACKBAR_MS}
        onClose={() => {
          setSnackMessage();
        }}
        message={snackMessage}
      />
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Total: </Typography>
          <Typography variant="h3">$ {totalPrice}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {items.map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid key={index} item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="button">Producto {index + 1}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  name="id"
                  getOptionLabel={(option) =>
                    `${option.description} | ${option.barcode}`
                  }
                  filterOptions={(x) => x}
                  options={options}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    handleAutocompleteOnChange(index)(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    searchProduct(storeId, newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Ingresa código de barras o nombre del producto"
                      fullWidth
                    />
                  )}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  label="Cantidad"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  fullWidth
                  name="quantity"
                  value={items[index].quantity}
                  onChange={handleOnChange(index)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={handleDeleteItem(index)}
                >
                  Eliminar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button fullWidth variant="outlined" onClick={handleAddItem}>
            Agregar producto
          </Button>
        </Grid>
        {items.length > 0 && (
          <Grid item xs={12} sx={{ mt: 5 }}>
            <LoadingButton
              fullWidth
              variant="contained"
              loading={sendingForm}
              onClick={handleEndSale}
              disabled={isFormUncompleted}
            >
              Finalizar Compra
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </>
  );
}

SaleForm.propTypes = {
  storeId: PropTypes.string.isRequired,
};
