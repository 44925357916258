import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Auth from '@aws-amplify/auth';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const { REACT_APP_BACKEND_URL } = process.env;

export default function StoreSelector({ onStoreSelection }) {
  const [stores, setStores] = useState();
  const [currentSelection, setCurrentSelection] = useState('');

  const handleChange = (event) => {
    onStoreSelection(event.target.value);
    setCurrentSelection(event.target.value);
  };

  useEffect(async () => {
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getIdToken().getJwtToken();

    fetch(`${REACT_APP_BACKEND_URL}/stores`, {
      headers: {
        authorization: accessToken,
      },
    })
      .then((response) => response.json())
      .then(({ data }) => setStores(data));
  }, []);

  if (!stores) return <CircularProgress />;

  return (
    <FormControl fullWidth>
      <InputLabel id="store-select-label">Tienda</InputLabel>
      <Select
        labelId="store-select-label"
        id="store-select"
        label="Age"
        value={currentSelection}
        onChange={handleChange}
      >
        {stores.map((store) => (
          <MenuItem key={store.id} value={store.id}>
            {store.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

StoreSelector.propTypes = {
  onStoreSelection: PropTypes.func.isRequired,
};
