import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import StoreSelector from './StoreSelector';
import SaleForm from './SaleForm';

export default function Home() {
  const [selectedStore, setSelectedStore] = useState();

  const handleStoreSelection = (storeId) => {
    setSelectedStore(storeId);
  };

  return (
    <>
      <Stack spacing={2}>
        <StoreSelector onStoreSelection={handleStoreSelection} />
        {selectedStore && <SaleForm storeId={selectedStore} />}
      </Stack>
    </>
  );
}
